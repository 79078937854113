import { EntityFirestore } from "core/models";
import type { PaymentCar, PaymentEntityData } from "core/typings";

export class PaymentEntity extends EntityFirestore<PaymentEntityData> {
	static readonly collPath: string = "payments";
	static readonly usesSubscription = false;

	totalAmount: number;
	paymentCars: PaymentCar[];

	createdBy: string;
	createdAt: Date;

	constructor(data: PaymentEntityData) {
		super(data);

		this.totalAmount = data.totalAmount;
		this.paymentCars = data.paymentCars;
		this.createdBy = data.createdBy;
		this.createdAt = new Date(data.createdAt);
	}

	static convertTimestampsFromFirestore(data) {
		super.convertTimestampsFromFirestore(data);

		data.createdAt = data.createdAt.toDate();
	}
}
