// Route
export const homeUrl = "/home";
export const vehiclesUrl = "/vehicles";
export const tablesUrl = "/tables";
export const tablesUserUrl = "/tables/user";
export const settingUrl = "/setting";
export const balanceUrl = "/balance";
export const payBalanceUrl = "/pay-balance";
export const carfaxUrl = "/carfax";
export const chatUrl = "/chat";

// Domain name project
export const DOMAIN_NAME = "trackyourvin.com";
