import { collection, doc } from "firebase/firestore";
import type {
	DocumentData,
	SnapshotOptions,
	CollectionReference,
	QueryDocumentSnapshot,
	FirestoreDataConverter,
} from "firebase/firestore";

import { firestore } from "global/firebase";
import type { BaseSubFirestoreData } from "core/typings";

export class BaseSubFirestore<
	T extends BaseSubFirestoreData = BaseSubFirestoreData,
> {
	static readonly collPath: string;

	protected collRef: CollectionReference<DocumentData, DocumentData>;
	id: string;

	static converter(): FirestoreDataConverter<DocumentData, DocumentData> {
		return {
			fromFirestore: (snapshot, options) =>
				this.fromFirestore(snapshot, options),
			toFirestore: (entityData) => this.toFirestore(entityData),
		};
	}

	static fromFirestore(
		snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
		options: SnapshotOptions,
	) {
		const data = snapshot.data(options);
		this.convertTimestampsFromFirestore(data);

		const docRef = snapshot.ref.parent.parent;
		const collRef = snapshot.ref.parent;
		const id = snapshot.id;

		const entity = new this({
			id,
			collPath: collRef.id,
			docId: docRef.id,
			...data,
		});

		return entity;
	}

	static convertTimestampsFromFirestore(_data: DocumentData) {
		//
	}

	static toFirestore(entityData): DocumentData {
		const { id, collRef, ...data } = entityData;

		return data;
	}

	constructor(data: T) {
		const cls = this.getClass();

		this.collRef = collection(
			firestore,
			`${data.collPath}/${data.docId}/${cls.collPath}`,
		).withConverter(cls.converter());

		this.id = data.id ? data.id : this.getID();
	}

	getID() {
		return doc(this.collRef).id;
	}

	getClass() {
		return this.constructor as any;
	}

	clone(): this {
		const cls = this.getClass();
		const newEntity = new cls(this);
		newEntity.id = this.id;
		return newEntity;
	}

	toData() {
		const { collRef, ...data } = this.clone();
		return structuredClone(data) as any;
	}
}
