import { DocumentSubFirestore } from "core/models";
import type { ChatMessageSubData } from "core/typings";

export class ChatMessageSub extends DocumentSubFirestore<ChatMessageSubData> {
	static readonly collPath = "messages";
	// ==================== Class Properties ====================

	// ==================== Instance Properties ====================
	message: string;
	date: Date;
	userLogin: string;
	userName: string;

	// ==================== Class Methods ====================

	// ==================== Instance Methods ====================
	constructor(data: ChatMessageSubData) {
		super(data);

		this.message = data.message;
		this.date = new Date(data.date);
		this.userLogin = data.userLogin;
		this.userName = data.userName;
	}

	static convertTimestampsFromFirestore(data) {
		super.convertTimestampsFromFirestore(data);

		data.date = data.date.toDate();
	}
}
