import { createStore } from "@stencil/store";
import type { SessionService, SessionData } from "core/services";

export interface SessionStore {
	sessions: SessionData[];
	session: SessionService;
}

const { state } = createStore<SessionStore>({
	sessions: [],
	session: null,
});

export { state as sessionsStore };
