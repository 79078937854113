import { EntityFirestore } from "core/models";
import type { ChatEntityData } from "core/typings";

export class ChatEntity extends EntityFirestore<ChatEntityData> {
	static readonly collPath: string = "chats";

	static readonly usesSubscription = false;

	imageUrl: string;
	vin: string;
	model: string;

	adminUser: string;

	updateBy: string;
	updateAt: Date;

	createdByName: string;
	createdBy: string;
	createdAt: Date;

	constructor(data: ChatEntityData) {
		super(data);

		this.imageUrl = data.imageUrl;
		this.vin = data.vin;
		this.model = data.model;

		this.adminUser = data.adminUser;

		this.updateBy = data.updateBy;
		this.updateAt = new Date(data.updateAt);


		this.createdByName = data.createdByName;
		this.createdBy = data.createdBy;
		this.createdAt = new Date(data.createdAt);
	}

	static convertTimestampsFromFirestore(data) {
		super.convertTimestampsFromFirestore(data);

		data.updateAt = data.updateAt.toDate();
		data.createdAt = data.createdAt.toDate();
	}
}
