import { createStore } from "@stencil/store";
import { presentModal } from "shared/helpers";
import type { UserEntity, DeliveryEntity, PayBalanceEntity } from "core/models";

export interface GlobalStore {
	user: UserEntity;
	theme: boolean;
	users: UserEntity[];
	deliveries: DeliveryEntity[];
	payBalances: PayBalanceEntity[];
}

const { state, onChange } = createStore<GlobalStore>({
	user: null,
	theme: null,
	users: [],
	deliveries: [],
	payBalances: [],
});

onChange("user", (user) => {
	if (user)
		if (!user.agreements) {
			presentModal("agreements-modal", {});
		}
});

onChange("theme", (shouldAdd) => {
	document.documentElement.classList.toggle("ion-palette-dark", shouldAdd);
});

export default state;
