import { Timestamp } from "firebase/firestore";
import type { FileInfoData } from "core/typings";

export class FileInfo {
	// ==================== Class Properties ====================

	// ==================== Instance Properties ====================
	fileName: string;
	fileSize: number;
	fileType: string;
	fileUrl: string;
	fileUploadDate: Date;

	// ==================== Class Methods ====================

	// ==================== Instance Methods ====================
	constructor(data: FileInfoData) {
		this.fileName = data.fileName;
		this.fileSize = data.fileSize;
		this.fileType = data.fileType;
		if (data.fileUploadDate instanceof Date) {
			this.fileUploadDate = data.fileUploadDate;
		} else {
			if (data.fileUploadDate instanceof Timestamp) {
				this.fileUploadDate = data.fileUploadDate.toDate();
			} else {
				this.fileUploadDate = null;
			}
		}
		this.fileUrl = data.fileUrl;
	}
}
