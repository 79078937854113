import { EntityFirestore } from "core/models";
import type { CalculatorIndicesEntityData } from "core/typings";
import store from "global/store";

export class CalculatorIndicesEntity extends EntityFirestore<CalculatorIndicesEntityData> {
	static readonly collPath: string = "calculator_indices";
	static readonly usesSubscription = true;

	name: string;
	index: number;

	constructor(data?: CalculatorIndicesEntityData) {
		super(data);

		if (data) {
			this.name = data.name;
			this.index = data.index;
		} else {
			this.name = "";
			this.index = null;
		}
	}

	static get cachedCollRef() {
		if (store.user === null || store.user.role !== "admin") {
			return null;
		}

		return this.collRef;
	}
}
