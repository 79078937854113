import { BaseSubFirestore } from "core/models";

import {
	deleteDoc,
	collection,
	doc,
	getDoc,
	onSnapshot,
	setDoc,
	updateDoc,
	getDocs,
	query,
	where,
} from "firebase/firestore";
import type { WhereFilterOp, FieldPath, Unsubscribe } from "firebase/firestore";
import type { DocumentSubFirestoreData } from "core/typings";

import { firestore } from "global/firebase";

export class DocumentSubFirestore<
	T extends DocumentSubFirestoreData = DocumentSubFirestoreData,
> extends BaseSubFirestore<T> {
	static readonly usesBundle: boolean = false;

	static async create(
		collPath: string,
		docId: string,
		id: string,
		data?: any,
	): Promise<any> {
		const document = new this(data);

		document.id = id;
		const documentData = document.toData();
		const docRef = doc(
			firestore,
			collPath,
			docId,
			this.collPath,
			id,
		).withConverter(this.converter());
		return setDoc(docRef, documentData)
			.then(() => {
				return document;
			})
			.catch((err) => {
				console.log(err);
				return document;
			});
	}

	static async get(collPath: string, docId: string, id: string): Promise<any> {
		const docRef = doc(
			firestore,
			collPath,
			docId,
			this.collPath,
			id,
		).withConverter(this.converter());

		return getDoc(docRef)
			.then((doc) => {
				if (doc.exists()) {
					const document = doc.data();
					document.id = id;

					return document;
				}
				return null;
			})
			.catch((err) => {
				console.log(`Error getting document with id - ${id} : ${err}`);
				return null;
			});
	}

	static async getAll(collPath: string, docId: string): Promise<any[]> {
		return getDocs(collection(firestore, collPath, docId, this.collPath))
			.then((querySnapshot) => {
				const documents = [];

				querySnapshot.forEach((doc) => {
					const document = doc.data();
					document.id = doc.id;

					documents.push(document);
				});

				return documents;
			})
			.catch((err) => {
				console.log(err);
				return [];
			});
	}

	static async getAllQuery(
		collPath: string,
		docId: string,
		fieldPath: string | FieldPath,
		opStr: WhereFilterOp,
		value: unknown,
	): Promise<any[]> {
		const q = query(
			collection(firestore, collPath, docId, this.collPath),
			where(fieldPath, opStr, value),
		);

		return getDocs(q)
			.then((querySnapshot) => {
				const documents = [];

				querySnapshot.forEach((doc) => {
					const document = doc.data();
					document.id = doc.id;

					documents.push(document);
				});

				return documents;
			})
			.catch((err) => {
				console.log(err);
				return [];
			});
	}

	async create(): Promise<string> {
		const docRef = doc(this.collRef, this.id);
		const documentData = this.toData();

		const { id, ...data } = documentData;

		return setDoc(docRef, data)
			.then(() => {
				return this.id;
			})
			.catch((err) => {
				console.log(err);
				return this.id;
			});
	}

	async update(): Promise<string> {
		const docRef = doc(this.collRef, this.id);
		const documentData = this.toData();

		const { id, ...data } = documentData;

		return updateDoc(docRef, data)
			.then(() => {
				return this.id;
			})
			.catch((err) => {
				console.log(err);
				return this.id;
			});
	}

	async delete(): Promise<string> {
		const docRef = doc(this.collRef, this.id);

		return deleteDoc(docRef)
			.then(() => {
				return this.id;
			})
			.catch((err) => {
				console.log(err);
				return this.id;
			});
	}

	subscribeDocumentChanged(callback: (data: any) => void): Unsubscribe {
		const cls = this.getClass();
		const docRef = doc(this.collRef, this.id).withConverter(cls.converter());
		const unsubscribe = onSnapshot(
			docRef,
			(doc) => {
				const documentNew = doc.data();
				if (documentNew) {
					documentNew.id = this.id;

					callback(documentNew);
				}
			},
			(error) => {
				console.log(error);
			},
		);
		return unsubscribe;
	}

	unsubscribeDocumentChanged(unsubscribe: Unsubscribe): void {
		unsubscribe();
	}
}
