import { EntityFirestore, type DeliveryEntity } from "core/models";
import type { UserBalanceEntityData } from "core/typings";
import forEach from "lodash/forEach";
import store from "global/store";

export class UserBalanceEntity extends EntityFirestore<UserBalanceEntityData> {
	static readonly collPath: string = "user_balances";
	static readonly usesSubscription = false;

	balance: number;

	createdByName: string;
	createdBy: string;
	createdAt: Date;

	getDebt(deliveries: DeliveryEntity[], balances = false) {
		let debt = 0;

		if (
			balances &&
			(store.user.role === "admin" || store.user.role === "managerPlus")
		) {
			let userDeliveries = [];
			const user = store.users.find((user) => user.login === this.createdBy);

			switch (user.role) {
				case "manager":
				case "managerPlus": {
					userDeliveries = deliveries.filter(
						(delivery) => delivery.manager === user.name,
					);
					break;
				}
				case "client": {
					userDeliveries = deliveries.filter(
						(delivery) => delivery.client === user.name,
					);
					break;
				}
				default:
					break;
			}

			forEach(userDeliveries, (delivery) => {
				if (delivery.balanceResidue <= 0) {
					return;
				}

				debt += delivery.balanceResidue;
			});

			return debt;
		}

		forEach(deliveries, (delivery) => {
			if (delivery.balanceResidue <= 0) {
				return;
			}

			debt += delivery.balanceResidue;
		});

		return debt;
	}

	getOverpayment(deliveries: DeliveryEntity[], balances = false) {
		let overpayment = 0;

		if (
			balances &&
			(store.user.role === "admin" || store.user.role === "managerPlus")
		) {
			let userDeliveries = [];
			const user = store.users.find((user) => user.login === this.createdBy);

			switch (user.role) {
				case "manager":
				case "managerPlus": {
					userDeliveries = deliveries.filter(
						(delivery) => delivery.manager === user.name,
					);
					break;
				}
				case "client": {
					userDeliveries = deliveries.filter(
						(delivery) => delivery.client === user.name,
					);
					break;
				}
				default:
					break;
			}

			forEach(userDeliveries, (delivery) => {
				if (delivery.balanceResidue >= 0) {
					return;
				}
				overpayment += Math.abs(delivery.balanceResidue);
			});

			return overpayment;
		}

		forEach(deliveries, (delivery) => {
			if (delivery.balanceResidue >= 0) {
				return;
			}
			overpayment += Math.abs(delivery.balanceResidue);
		});

		return overpayment;
	}

	constructor(data?: UserBalanceEntityData) {
		super(data);

		if (data) {
			this.balance = data.balance;
			this.createdByName = data.createdByName;
			this.createdBy = data.createdBy;
			this.createdAt = new Date(data.createdAt);
		} else {
			this.balance = 0;
			this.createdByName = store.user.name;
			this.createdBy = store.user.login;
			this.createdAt = new Date();
		}
	}

	static convertTimestampsFromFirestore(data) {
		super.convertTimestampsFromFirestore(data);

		data.createdAt = data.createdAt.toDate();
	}
}
