import { DocumentFirestore } from "./document.firestore";
import type {
	EntityFirestoreData,
	BaseConstructorClassThis,
} from "core/typings";

import {
	onSnapshot,
	getDocs,
	loadBundle,
	getDocsFromCache,
} from "firebase/firestore";
import { getBundleUrl } from "shared/helpers";
import { firestore } from "global/firebase";
import type {
	Query,
	CollectionReference,
	DocumentData,
	Unsubscribe,
} from "firebase/firestore";
import { Subject } from "rxjs";
import forEach from "lodash/forEach";

export class EntityFirestore<
	T extends EntityFirestoreData = EntityFirestoreData,
> extends DocumentFirestore<T> {
	static cachedItems: any[] = null;
	static readonly usesSubscription: boolean = false;

	static observableEntityAdded: Subject<unknown>;
	static observableEntityModified: Subject<unknown>;
	static observableEntityRemoved: Subject<unknown>;
	static subscriptionEntityActions: Unsubscribe;

	static get cachedCollRef():
		| Query<DocumentData, DocumentData>
		| CollectionReference<DocumentData, DocumentData> {
		return this.collRef;
	}

	static async init(ancestors = false) {
		if (ancestors) {
			await super.init(true);

			await this.fetchInitialData();
			this.subscribe();
		} else {
			if (!this.onReady) {
				this.onReady = new Promise((resolve) => {
					super
						.init(true)
						.then(() => {
							return this.fetchInitialData();
						})
						.then(() => {
							return this.subscribe();
						})
						.then(() => {
							resolve();
						});
				});
			}
			await this.onReady;
		}
	}

	static async refresh() {
		this.unsubscribe();
		this.cachedItems = null;

		await this.fetchInitialData();
		this.subscribe();
	}

	static subscribe(): Unsubscribe {
		if (this.usesSubscription) {
			if (!this.observableEntityAdded) {
				this.observableEntityAdded = new Subject();
			}
			if (!this.observableEntityModified) {
				this.observableEntityModified = new Subject();
			}
			if (!this.observableEntityRemoved) {
				this.observableEntityRemoved = new Subject();
			}

			if (!this.subscriptionEntityActions) {
				const ref = this.cachedCollRef;

				if (ref === null) {
					return;
				}

				this.subscriptionEntityActions = onSnapshot(ref, (snapshot) => {
					const docChanges = snapshot.docChanges();

					forEach(docChanges, (change) => {
						switch (change.type) {
							case "added": {
								const entity = change.doc.data();
								const id = change.doc.id;
								entity.id = id;
								const index = this.cachedItems.findIndex((el) => el.id === id);
								if (index === -1) {
									this.cachedItems.unshift(entity);
									this.sortCachedItems();
									this.observableEntityAdded.next(entity);
									this.cachedItemsUpdate();
								}

								break;
							}
							case "modified": {
								const entity = change.doc.data();
								const id = change.doc.id;
								entity.id = id;

								const index = this.cachedItems.findIndex((el) => el.id === id);
								if (index > -1) {
									this.cachedItems[index] = entity;
									this.observableEntityModified.next(entity);
								} else {
									this.cachedItems.push(entity);
									this.observableEntityAdded.next(entity);
								}
								this.cachedItemsUpdate();

								break;
							}
							case "removed": {
								const id = change.doc.id;
								this.cachedItems = this.cachedItems.filter(
									(entity) => entity.id !== id,
								);
								this.observableEntityRemoved.next(change.doc);
								this.cachedItemsUpdate();

								break;
							}
						}
					});
				});
				return this.subscriptionEntityActions;
			}
		}
	}

	static unsubscribe() {
		if (this.subscriptionEntityActions) {
			this.subscriptionEntityActions();
			this.subscriptionEntityActions = null;
		}
	}

	static async fetchInitialData() {
		if (this.cachedItems !== null) return null;

		this.cachedItems = [];

		const ref = this.cachedCollRef;

		if (ref === null) {
			return;
		}

		if (this.usesBundle) {
			const bundleUrl = getBundleUrl(this.collPath);
			const response = await fetch(bundleUrl);
			if (response.ok) {
				const loadBundleTask = loadBundle(firestore, await response.body);
				let totalDocuments = 0;
				loadBundleTask.onProgress(
					(progress) => {
						totalDocuments = progress.totalDocuments;
					},
					(err) => {
						console.log("Error: ", err.message);
					},
					() => {
						console.log(`Loading ${totalDocuments} entities complete!`);
					},
				);
				await loadBundleTask;

				return getDocsFromCache(ref)
					.then((docs) => {
						return docs.forEach((doc: DocumentData) => {
							const entity = doc.data();
							entity.id = doc.id;
							this.cachedItems.push(entity);
						});
					})
					.then(() => {
						return this.sortCachedItems();
					})
					.then(() => {
						return this.cachedItemsUpdate();
					})
					.catch((err) => {
						console.log(`Error getting ${this.name} list: ${err}`);
					});
			}
		}

		return await getDocs(ref)
			.then((docs) => {
				return docs.forEach((doc) => {
					const entity = doc.data();
					entity.id = doc.id;
					this.cachedItems.push(entity);
				});
			})
			.then(() => {
				return this.sortCachedItems();
			})
			.then(() => {
				return this.cachedItemsUpdate();
			})
			.catch((err) => {
				console.log(`Error getting ${this.name} list: ${err}`);
			});
	}

	static getCachedItem<T extends EntityFirestore>(
		this: BaseConstructorClassThis<T>,
		field: string,
		value: any,
	): T {
		return this.cachedItems.find((entity) => entity[field] === value);
	}

	static getCachedItems<T extends EntityFirestore>(
		this: BaseConstructorClassThis<T>,
		field: string,
		value: any,
	): T[] {
		return this.cachedItems.filter((entity) => entity[field] === value);
	}

	static sortCachedItems() {}

	static cachedItemsUpdate() {}
}
